import {useEffect} from "react";
import {navigate} from "gatsby";

// markup
const PricingPage = () => {
    useEffect(() => {
        navigate("/#pricing");
    });

    return null;
}

export default PricingPage
